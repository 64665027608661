html,
body {
  scroll-behavior: smooth;
  font-family: sans-serif;
}
.hidden {
  display: none !important;
}

.close {
  color: #aaa;
  float: right;

  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal {
  margin: 0 auto;
  width: 320px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;
}
.madal button.close {
  width: 30px;

  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

.madal p {
  margin-bottom: 40px;
}
.modal button.install {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 14px;

  color: rgb(197, 171, 171);
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

body {
  margin: 0;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #aeb0b6;
}
::-webkit-scrollbar-thumb {
  background: #1b1e3d;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #1b1e3d;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    line-height: normal;
  }
}
